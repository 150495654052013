import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import Pagination from "react-js-pagination";

function Community() {
  const [searchId, setSearchId] = useState('')
  const [items, setItems] = useState([])
  const [total, setTotal] = useState(0);
  const [currentpage, setCurrentpage] = useState(1);

  async function getElements(path) {
    await axios(path, { method: 'get' })
    .then((res) => {
      setItems(res.data.posts)
      setTotal(res.data.post_cnt)
    })
  }

  const setPage = (e) => {
    setCurrentpage(e);
  };

  useEffect(() => {
    getElements(`${process.env.REACT_APP_BASE_URL}/api/community/get/posts?offset=${currentpage}&limit=10&type=Normal`)
  }, [currentpage]);

  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
        width: window.innerWidth,
        height: window.innerHeight
    }
  }

  useEffect(() => {
      const updateDimension = () => {
          setScreenSize(getCurrentDimension())
      }
      window.addEventListener('resize', updateDimension);
  
  
      return(() => {
          window.removeEventListener('resize', updateDimension);
      })
  }, [screenSize]);

  return (
    <div>
      <div className="SectionList">
        <section className="ContentBox" id="Community">
          <div className="inner">          
            <div className="Community_List">
              <a href="./CommunityForm" className="review_pop_head_menuItem">
                <i class="fa-solid fa-pencil"></i> 
                &nbsp;&nbsp;Write new
              </a>
              
              {items.length > 0 ? 
                <>
                  {screenSize.width <= 900 ? 
                    <>
                      {Object.values(items).map((value, index) => (
                        <a href={`/Community/${value.id}`} key={index}>
                          <div className="community-box">
                            <div className="inner-community-box">
                              {value.title}
                              <div className="community-bottom">
                                <p>{value.author.nickname}</p>
                                <i class="fa-solid fa-comment">&nbsp;{value.comment}</i>
                                <i class="fa-solid fa-eye">&nbsp;{value.views}</i>
                                <i class="fa-solid fa-calendar">&nbsp;{moment(value.createdAt).format('YYYY-MM-DD')}</i>
                              </div>
                            </div>
                            
                            <div className="tags">
                              {value.category}
                            </div>
                          </div>
                        </a>
                      ))}
                    </>
                    :
                    <>
                      <div className="Community_List_head">
                      <div className="Community_List_item_textHead">Brackets</div>
                      <div className="Community_List_item_title">Title</div>
                      <div className="Community_List_item_d">Comments</div>
                      <div className="Community_List_item_d">Poster</div>
                      <div className="Community_List_item_d">Views</div>
                      <div className="Community_List_item_dateTime">Date</div>
                    </div>

                    {Object.values(items).map((value, index) => (
                      <a href={`/Community/${value.id}`} className="Community_List_item" key={index}>
                        <div className="Community_List_item_textHead">{value.category}</div>
                        <div className="Community_List_item_title">{value.title}</div>
                        <div className="Community_List_item_d">{value.comment}</div>
                        <div className="Community_List_item_d">{value.author.nickname}</div>
                        <div className="Community_List_item_d">{value.views}</div>
                        <div className="Community_List_item_dateTime">{moment(value.createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
                      </a>
                    ))}
                    </>
                  }      
                </>
              :
                <h1 style={{ textAlign: 'center' }}>There are no posts.</h1>
              }
            </div>
            <div className="list_under_input">
              
            <form action={"/Community/search/" + searchId}>
              <input id="search" autoComplete="false" onChange={(e) => setSearchId(e.target.value)} />
              <button type="submit" id="PrimaryButton">search</button>
            </form>
            </div>
            <Pagination
              activePage={currentpage}
              totalItemsCount={total}
              itemsCountPerPage={10}
              pageRangeDisplayed={5}
              prevPageText={"‹"}
              nextPageText={"›"}
              onChange={setPage}
            />
          </div>
        </section>
      </div>
    </div>
  );
}

export default Community;
