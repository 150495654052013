import axios from 'axios';
import useSWR from 'swr'
import { getCookie } from '../util/cookies';

const fetcher = async (path) => {
  try {
    const res = await axios(path, {
      method: 'post',
      headers: {
        Authorization: `bearer ${getCookie('token')}`
      }
    });
    return res.data;
  } catch (err) {
    alert('어드민이 아닌 경우 사용할 수 없습니다.');
    window.location.href = '/';
    throw err;
  }
}

function AdminMain() {
  const { _, error } = useSWR(`${process.env.REACT_APP_BASE_URL}/api/auth/info`, fetcher)

  if (error) {
    alert('어드민이 아닌 경우 사용할 수 없습니다.');
    window.location.href = '/';
    return;
  }

  return (
    <div>
      <div className="contentBox">
        <h1>ADMIN</h1>


        <div className="Content-ButtonBox">
          <a href="./AdminImg" className="Content-Button">배너 이미지 관리하기</a>
          <a href="./AdminKeyword" className="Content-Button">금지어 설정하기</a>
        </div>
      </div>
    </div>
  );
}
  
export default AdminMain;