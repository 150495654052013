function GoogleAd() {
    return (
      <div>
        <div className="google-ad">
          Google Ad
        </div>
      </div>
    );
  }
  
  export default GoogleAd;
  