import React, { useState } from "react";
import axios from 'axios';
import ReactQuill from "react-quill";
import {getCookie} from '../util/cookies'
function CommunityForm() {
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('TIPS');
  const [content, setContent] = useState('');
  const [hashtags, setHashtags] = useState('');

  function onChange(e) {
    setContent(e)
  }

  async function onSubmit(e) {
    e.preventDefault();
    await axios(`${process.env.REACT_APP_BASE_URL}/api/community/post/insert`, {
      method: 'post',
      headers: {
        Authorization: `bearer ${getCookie('token')}`
      },
      data: {
        type: 'Normal',
        hashtags,
        title, 
        content, 
        category, 
      }
    }).then((res) => {
      window.location.href = '/Community'
    }).catch((err) => {
      alert(err.response.data.message)
    })
  }

  return (
    <div>
      <div className="SectionList">
        <section className="ContentBox">
          <div className="inner">
            <form
              onSubmit={onSubmit}
              className="review-form"
              id="SignUpForm"
              autoComplete="false"
              method="post"
            >
              <h2>Community</h2>
              <div className="review-inner">
                <div className="form-inputGroup">
                  <label className="form-namelLabel" htmlFor="CommTitle">
                    <div className="form-dot"></div> &nbsp;&nbsp; Title
                  </label>
                  <input
                    maxLength={24}
                    autoComplete="false"
                    type="text"
                    id="CommTitle"
                    name="CommTitle"
                    placeholder="Title"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>

                <div className="form-inputGroup">
                  <label className="form-namelLabel" htmlFor="CommBrackets">
                    <div className="form-dot"></div> &nbsp;&nbsp; Brackets
                  </label>
                  <select 
                    id="CommBrackets"
                    name="CommBrackets"
                    onChange={(e) => setCategory(e.target.value)}
                    placeholder="Title">
                    
                    <option value="TIPS" selected>TIPS</option>
                    <option value="Q&A" >Q&A</option>
                    <option value="Free talk" >Free talk</option>

                  </select>
                </div>

                <div className="form-inputGroup">
                  <label className="form-namelLabel" htmlFor="reviewContent">
                    <div className="form-dot"></div> &nbsp;&nbsp; Content
                  </label>

                  <ReactQuill
                    style={{ height: '200px', marginBottom: '50px' }}
                    theme="snow"
                    value={content}
                    onChange={onChange}
                    modules={{
                      toolbar: [
                        [{ header: '1' }, { header: '2' }, { font: [] }],
                        [{ size: [] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ list: 'ordered' }, { list: 'bullet' },
                         { indent: '-1' }, { indent: '+1' }],
                        ['link', 'image', 'video'],
                        ['clean']
                      ],
                    }}
                    formats={[
                      'header', 'font', 'size',
                      'bold', 'italic', 'underline', 'strike', 'blockquote',
                      'list', 'bullet', 'indent',
                      'link', 'image', 'video'
                    ]}
                  />
                </div>

            
                <div className="form-inputGroup">
              <label className="form-namelLabel" htmlFor="hashtag">
                    <div className="form-dot"></div> &nbsp;&nbsp; Hashtag (Detach using the space bar)
                  </label>
                  <input  type="text" name="hashtag" id="hashtag" onChange={(e) => setHashtags(e.target.value)}/>
              </div>


                <button className="form-button">SUBMIT</button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
}

export default CommunityForm;
